'use strict';

angular.module('enervexSalesappApp').controller('LocationDatasCtrl', function($scope, $http, LocationData, Auth, State, $stateParams, _, Util, Property, Flash, FileUploader, SystemTask) {
	$scope.configs = Property.configs()
	$scope.ctx = {
		active: "",
		showDeleteOptions: ($scope.accountId) ? false : true,
		locationDataPageSize: 500
	}
	State.query().$promise.then(function(result){
		$scope.states = result
	})
	$scope.spaced = function(value) {
		return value.split(",").join(", ")
	}
	$scope.search = function(topLevel) {
		if (!topLevel) {
			return
		}
		if ($scope.configs.locationDataPageSize){
			$scope.ctx.locationDataPageSize = parseInt($scope.configs.locationDataPageSize)
		}
		var params = {limit:$scope.ctx.locationDataPageSize};
		if ($scope.ctx.state && $scope.state != ''){
			params.state = $scope.ctx.state;
		}
		if ($scope.ctx.active != ''){
			params.active = $scope.ctx.active;
		}
		if ($scope.ctx.city && $scope.ctx.city != '') {
			params.city = $scope.ctx.city
		}
		if ($scope.ctx.zip && $scope.ctx.zip != '') {
			params.zip = $scope.ctx.zip
		}
		Flash.clear()
		Flash.create("info", '<strong>Search Location Datas...</strong>', 0, {}, true);
		LocationData.query(params).$promise.then(function(locationDatas){
			$scope.locationDatas = locationDatas
			if (locationDatas.length == $scope.ctx.locationDataPageSize) {
				Flash.clear()
				var message = "Showing the max " + locationDatas.length + " location datas, please narrow your search";
				Flash.create('danger', '<strong>Serach Warning!</strong> ' + message, 0, {}, true);
			} else {
				Flash.clear()
				Flash.create("info", '<strong>Search Location Datas</strong> found ' + locationDatas.length + ' items.', 0, {}, true);
			}
		}).catch(function(err){
			console.log("error", err)
			Flash.clear()
			Flash.create('danger', '<strong>Search Error!</strong> ' + err.data.message + ". Try selecting more parameters", 0, {}, true);
		});
	}
	$scope.export = function(e) {
		var params = [];
		var hasParams = false
		Flash.clear()
		if ($scope.ctx.city && $scope.ctx.city != ''){
			params.push("city="+$scope.ctx.city)
			hasParams = true
		}
		if ($scope.ctx.state && $scope.ctx.state != '') {
			params.push('state=' + $scope.ctx.state)
			hasParams = true
		}
		if ($scope.ctx.active && $scope.ctx.active != '') {
			params.push('active=' + $scope.ctx.active)
		}
		if ($scope.ctx.zip && $scope.ctx.zip != ''){
			params.push("zip_code="+$scope.ctx.zip)
			hasParams = true
		}
		if (!hasParams) {
			Flash.create('danger', '<strong>Export Error!</strong> try selecting more parameters', 0, {}, true);
			return
		}
		var url = "/api/location-datas/export.csv";
		if (params.length > 0) {
			url = url + "?" + params.join("&")
		}
		window.location.href = url;
	}
	$scope.uploader = new FileUploader({});
	function pollTask(systemTask) {
		$scope.systemTask = systemTask
		var diff = new Date().getTime() - new Date(systemTask.startedAt).getTime()
		var level = "warning"
		var additionalNotes = ""
		if ($scope.systemTask.status == "running") {
			additionalNotes = " started " + diff/1000 + " seconds ago"
		} else if ($scope.systemTask.status == "success") {
			level = "info"
		} else if ($scope.systemTask.status == "errored") {
			level = "danger"
		}

		Flash.clear()
		var anchor = '<a href="/system-tasks/' + $scope.systemTask._id + '">' + $scope.systemTask.taskNumber + '</a>'
		Flash.create(level, '<strong>Import system task ' + anchor + 
			" is " + $scope.systemTask.status + '</strong> ' + additionalNotes + $scope.systemTask.notes, 0, {}, true);
		setTimeout(function() {
			if ($scope.systemTask && $scope.systemTask.status == "running") {
				SystemTask.get({
					id: $scope.systemTask.id
				}).$promise.then(function(res) {
					pollTask(res)
				})
			}
		}, 5000)
	}
	$scope.import = function() {
		$scope.systemTask = null
		_.each($scope.uploader.queue, function(data){
			var fd = new FormData();
			fd.append('name', data._file.name);
			fd.append('asset', data._file);
			$scope.uploadResponse = false;
			Flash.clear()
			LocationData.import(fd).$promise.then(function(res){
				pollTask(res)
				// $scope.uploadResponse = res
				$scope.uploader.clearQueue()
			}).catch(function(e){
				var message = "";
				if (e.data ) {
					message = message + e.data.message
				} else {
					message = message + e
				}
				Flash.create('danger', '<strong>Failure!</strong> ' + message, 0, {}, true);
				$scope.uploader.clearQueue()
			})
		});
	}
});
